<template>
  <v-container>
    <v-tabs v-model="tab" grow show-arrows>
      <v-tab v-for="(tab, value) in tabs" :value="value" :disabled="tab.disabled" nuxt="true" :to="`/configuration/${value}`" :class="{ 'selected-tab': tabIsSelected(value), 'unselected-tab': !tabIsSelected(value) }">
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <slot />
  </v-container>
</template>

<script setup lang="ts">
const tabs: { [key: string]: { title: string, disabled: boolean } } = {
  "device": { title: "Device", disabled: false },
  "location": { title: "Location", disabled: false },
  "alert": { title: "Alert", disabled: false },  
  // "setting": { title: "setting", disabled: false },
  // "profile": { title: "Profile", disabled: false },
}

  const route = useRoute();
  const tab = ref(route.path);
  const tabIsSelected = (value: string) => {
    return tab.value === value;
  };
</script>
<style scoped>
  .v-tabs,
  .v-tab {
    font-family: "Noto Sans", sans-serif;
  }

  .selected-tab {
    color: #001a21;
    font-weight: bold;
  }

  .unselected-tab {
    color: #808b95;
  }
</style>
